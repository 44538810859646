import common from "@/common";

let form_options = {}
form_options.company = []

export function reloadFieldData() {
  // init Arrays
  ;[
    form_options.company,
  ].forEach(arr => arr.length = 0)

  // Todo: no need to call this whilst account is not admin
  common.getCompanyOptions()
    .then(res => {
      form_options.company.push.apply(form_options.company, res)
    })
}

export default [
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    rule: 'required',
    options: form_options.company,
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityID',
    label: 'common.facility',
    type: 'slot',
    rule: 'required',
    slot: 'facility',
    show: common.isAdmin()
  },
  {
    name: 'groupName',
    label: 'group.groupName',
    type: 'text',
    rule: 'required|max: 20'
  },
  {
    name: 'isActive',
    label: 'group.isActive',
    type: 'select',
    rule: 'required',
    edit_show: true,
    options: [
      {text: common.getI18n('common.enable'), value: true},
      {text: common.getI18n('common.disable'), value: false},
    ],
  },
  {
    type: 'hr'
  },
  {
    name: 'isDefaultType',
    label: 'group.groupType',
    type: 'radio',
    rule: 'required',
    options: [
      {text: common.getI18n('common.preset_all'), value: true},
      {text: common.getI18n('common.custom'), value: false},
    ]
  },
  {
    name: 'staffIDs',
    label: 'group.groupStaff',
    type: 'slot',
    rule: 'required',
    slot: 'staff',
    show: 'isDefaultType',
    show_value: false
  },
  {
    type: 'blank',
    style: 'height: 20px',
    show: 'isDefaultType',
    show_value: false
  },
  {
    name: 'bedIDs',
    label: 'group.groupBed',
    type: 'slot',
    // rule: 'required',
    slot: 'bed',
    show: 'isDefaultType',
    show_value: false,
    validate_in_slot: true,
    slot_config: {
      name: 'bedID',
      label: 'common.bed',
      // rule: 'required',
    },
  },
  {
    type: 'blank',
    style: 'height: 20px',
    show: 'isDefaultType',
    show_value: false
  },
  {
    name: 'memberIDs',
    label: 'group.groupMember',
    type: 'slot',
    // rule: 'required',
    slot: 'member',
    show: 'isDefaultType',
    show_value: false
  },
  {
    type: 'hr'
  },
  {
    name: 'isDefaultAlert',
    label: 'group.alert',
    type: 'radio',
    rule: 'required',
    options: [
      {text: common.getI18n('group.alertPreset'), value: true},
      {text: common.getI18n('common.custom'), value: false},
    ]
  },
  {
    name: 'alertTimes',
    label: 'group.alertTime',
    type: 'slot',
    rule: 'required',
    validate_in_slot: true,
    slot: 'alertTime',
    slot_config: {
      name: 'alertTime',
      label: 'group.alertTime',
      rule: 'required',
    },
    show: 'isDefaultAlert',
    show_value: false
  }
]

<template>
  <div class="pb-1 group-edit">
    <b-row>
      <b-col cols="6" v-for="option in options">
        <b-form-checkbox
          name="staffIDs"
          :value="option.staffID"
          v-model="rowData['staffIDs']"
          class="mt-1 ml-1 mr-1"
        >
          {{option.staffName}}
        </b-form-checkbox>
      </b-col>
      <b-col v-if="options.length === 0">
        <b-form-checkbox
          name="staffIDs"
          class="mt-1 ml-1 mr-1"
          disabled
        >
          {{ $t('error.StaffIsNotFound') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox
} from 'bootstrap-vue'
import common from "@/common";

export default {
  name: "SlotStaff",
  components: {
    BRow,
    BCol,
    BFormCheckbox
  },
  props: {
    rowData: {type: Object},
    facilityId: {type: Number}
  },
  data() {
    return {
      options: []
    }
  },
  watch: {
    facilityId: function (facilityID) {
      this.rowData.staffIDs = []
      this.options = []
      this.getOptions(facilityID)
    }
  },
  mounted() {
    const facility_id = common.getFacilityId() ? common.getFacilityId() : 0
    this.getOptions(facility_id)
  },
  methods: {
    getOptions: function (facility_id) {
      if (!facility_id) return
      common.apiGetData('/group/GetStaffs?facilityID='+facility_id).then(res => {
        this.options = res.data.staffs
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <validation-observer
      tag="b-row"
      v-for="(item, itemIndex) in rowData.alertTimes"
      :key="item.tmp_id"
      class="mb-05"
    >
      <validation-provider
        tag="div"
        class="w-100"
        #default="{ errors }"
        :name="$t(slotConfig.label)"
        :rules="slotConfig.rule"
        :customMessages="slotConfig.custom_messages"
      >
        <b-col cols="12" class="range wh-form-range">
          <div><b-form-input type="time" v-model="item.startTime"/></div>
          <div>至</div>
          <div><b-form-input type="time" v-model="item.endTime"/></div>
          <b-button
            class="flex-shrink-0 ml-1"
            variant="outline-danger"
            @click="deleteTime(item.tmp_id)"
            :disabled="rowData.alertTimes.length === 1 && isMinimumOneItemRequired"
          >
            <!--{{ $t('common.delete') }}-->
            <feather-icon icon="MinusCircleIcon"/>
          </b-button>
        </b-col>
        <b-col cols="12" v-show="errors.length !== 0"><small class="text-danger">{{ errors[0] }}</small></b-col>
      </validation-provider>
    </validation-observer>

    <b-button
      variant="outline-primary" @click="addTime"
    >
      <!--{{ $t('common.add') }}-->
      <feather-icon icon="PlusCircleIcon"/>
    </b-button>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton
} from 'bootstrap-vue'
import common from "@/common";
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  max,
  digits,
  alphaDash,
  length,
  min_value,
} from '@validations'

export default {
  name: "SlotAlertTime",
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    flatPickr,
    ValidationProvider, ValidationObserver
  },
  props: {
    rowData: {type: Object},
    rootItem: {type: Object}
  },
  data() {
    return {
      tmp_id: 1,
      slotConfig: Object.assign({}, this.rootItem.slot_config)
    }
  },
  computed: {
    isMinimumOneItemRequired() {
      return this.rootItem.rule?.split('|')
        .includes('required')
    },
  },
  mounted() {
    for (const i in this.rowData.alertTimes) {
      this.rowData.alertTimes[i]['tmp_id'] = this.tmp_id++
    }
    if (this.isMinimumOneItemRequired && this.rowData.alertTimes.length === 0) {
      this.addTime()
    }
  },
  methods: {
    addTime: function () {
      this.rowData.alertTimes.push({tmp_id: this.tmp_id++, startTime: '', endTime: ''})
    },
    deleteTime: function (tmp_id) {
      for (const i in this.rowData.alertTimes) {
        if (this.rowData.alertTimes[i].tmp_id === tmp_id) {
          this.rowData.alertTimes.splice(i, 1)
        }
      }
    },
    buildData: function () {
      // for (const i in this.rowData.alertTimes) {
      //   if (!this.rowData.alertTimes[i].startTime || !this.rowData.alertTimes[i].endTime) {
      //     common.showAlert(common.getI18n('group.alertTimeError'))
      //     return false
      //   }
      // }

      return true

    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.wh-form-range {
  > div {
    height: auto;
  }
}
</style>
